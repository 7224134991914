import { api } from "../../utils";
import {
  CONTRACT_URL,
  DRAFT_URL,
  GET_CONTRACT_FILTERS_PAGINATION,
  GET_SINGLE_CONTRACT,
} from "../ApiConstants";

class ContractApi {
  static sharedInstance = new ContractApi();
  constructor() {
    if (ContractApi.sharedInstance != null) {
      return ContractApi.sharedInstance;
    }
  }
  //   Get Contracts Based on Store ID ..
  async getContractByStoreID(id) {
    try {
      const response = await api.get(`${CONTRACT_URL}/${id}`);
      const { success, data, message } = await response.data;
      if (success) {
        return data;
      } else {
        return message;
      }
    } catch (error) {
      return error;
    }
  }

  //   Get Contracts Based on Store ID with Offset and Limit  ..
  async getContractsFilters(id, body) {
    try {
      const response = await api.post(
        `${GET_CONTRACT_FILTERS_PAGINATION}/${id}`,
        body
      );
      const { success, data, message } = await response.data;
      if (response) {
        return response.data;
      } else {
        return message;
      }
    } catch (error) {
      return error;
    }
  }

  // Create Contract  ...
  async createContract(body) {
    console.log("form data in api  ===>", body);
    try {
      const response = await api.post(`${CONTRACT_URL}/create`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const { success, message } = await response.data;
      if (success) {
        return response.data;
      } else {
        return message;
      }
    } catch (error) {
      return error;
    }
  }

  // Create Contract  ...
  async createDuplicate(body) {
    try {
      const response = await api.post(`${CONTRACT_URL}/duplicate`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const { success, message } = await response.data;
      if (success) {
        return response.data;
      } else {
        return message;
      }
    } catch (error) {
      return error;
    }
  }

  // Get Contract By ID ...
  async getContractByID(id) {
    try {
      const response = await api.get(GET_SINGLE_CONTRACT + id);
      const { success, message } = await response.data;
      if (success) {
        return response.data.data;
      } else {
        return message;
      }
    } catch (error) {
      return error;
    }
  }
  // Get Contract By ID ...
  async updateContract(body, id) {
    try {
      const response = await api.put(`${CONTRACT_URL}/${id}`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const { success, message } = await response.data;
      if (success) {
        return response.data;
      } else {
        return message;
      }
    } catch (error) {
      return error;
    }
  }

  // Delete Contract  ..
  // Get Contract By ID ...
  async deleteContract(id) {
    try {
      const response = await api.delete(`${CONTRACT_URL}/${id}`);
      const { success, message } = await response.data;
      if (success) {
        return response.data;
      } else {
        return message;
      }
    } catch (error) {
      return error;
    }
  }

  // Search Contract By companyName or contractPerson ...
  async searchContract(id, query, offsetVal) {
    try {
      const response = await api.post(
        `${CONTRACT_URL}/search/${id}?q=${query}&&offset=${offsetVal}`
      );
      const { success, message } = await response.data;
      if (success) {
        return response.data;
      } else {
        return message;
      }
    } catch (error) {
      console.log("Search Contract error  ====>", error);
      return error;
    }
  }

  // Global Search Contract By companyName  contractPerson or Services Details...
  async globalSearchContract(query, body) {
    try {
      // const response = api.post('url', body)
      const response = await api.post(
        `${CONTRACT_URL}/globalSearch?q=${query}`,
        body
      );
      const { success, message } = await response.data;
      if (success) {
        return response.data;
      } else {
        return message;
      }
    } catch (error) {
      console.log("Global Search Contract error  ====>", error);
      return error;
    }
  }

  // Search Contract With Filters and limit  ....
  async searchContractsWithFiltersAndLimit(query, body) {
    try {
      // const response = api.post('url', body)
      const response = await api.post(
        `${CONTRACT_URL}/searchContractWithFilters?q=${query}`,
        body
      );
      const { success, message } = await response.data;
      if (success) {
        return response.data;
      } else {
        return message;
      }
    } catch (error) {
      console.log(" Search Contract error  ====>", error);
      return error;
    }
  }

  // Get Contract with filters and pagination
  async getContractsWithFiltersAndPagination(body) {
    try {
      const response = await api.post(
        `${CONTRACT_URL}/GetContractsByFilters`,
        body
      );
      const { success, message } = await response.data;
      if (success) {
        return response.data;
      } else {
        return message;
      }
    } catch (error) {
      console.log("get contracts with filters and pagination =====>", error);
    }
  }

  // Download contract ...
  async downloadAttachment(company, email, filename) {
    try {
      const response = await api.get(
        `${CONTRACT_URL}/${company}/${email}/${filename}`,
        {
          responseType: "blob",
        }
      );
      if (response.status === 200) {
        return response;
      } else {
        return null;
      }
    } catch (error) {
      console.log("download attachment api catch error", error);
    }
  }

  // Create Contract As Draft  ...
  async createDraftContract(body) {
    console.log("form data in api  ===>", body);
    try {
      const response = await api.post(`${DRAFT_URL}/create`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const { success, message } = await response.data;
      if (success) {
        return response.data;
      } else {
        return message;
      }
    } catch (error) {
      return error;
    }
  }

  // update draft ..
  async updateDraft(body, id) {
    try {
      const response = await api.put(`${DRAFT_URL}/${id}`, body, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      const { success, message } = await response.data;
      if (success) {
        return response.data;
      } else {
        return message;
      }
    } catch (error) {
      return error;
    }
  }

  // Get draft contracts..
  async getDraftContracts() {
    try {
      const response = await api.get(`${DRAFT_URL}/draftcontracts`);
      const { success, data } = response.data;
      if (success) {
        return data;
      }
    } catch (error) {
      return error.response.data;
    }
  }

  // Delete draft contract ..
  async deleteDraft(id) {
    try {
      const response = await api.delete(`${DRAFT_URL}/${id}`);
      const { success, message } = await response.data;
      if (success) {
        return response.data;
      } else {
        return message;
      }
    } catch (error) {
      return error;
    }
  }
}

export default ContractApi.sharedInstance;
