import React, { useEffect } from "react";
import {
  Dialog,
  Grid,
  DialogTitle,
  IconButton,
  TextField,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import { Close, Save } from "@mui/icons-material";
import { IMaskInput } from "react-imask";

import { useFormik } from "formik";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";

import { peopleValidationSchema } from "../../../../utils/validations";
import PeopleApi from "../../../../services/Api/People.Api";
import dynamicStyle from "./styles";

const initialValues = {
  name: "",
  email: "",
  phoneNo: "",
};

const FormDialog = (props) => {
  const { open, setOpen, peopleID, setPeopleID, refetch } = props;

  const classes = dynamicStyle();

  //   Handle close dialog ..
  const handleClose = () => {
    formik.resetForm();
    setOpen(false);
    setPeopleID(null);
  };

  //   Formik ..
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: peopleValidationSchema,
    onSubmit: (values) => {
      addPeople(values);
    },
  });

  // Get single people data ..

  const { data } = useQuery(
    ["SINGLE_PEOPLE_DATA", peopleID],
    () => PeopleApi.getSinglePeople(peopleID),
    { enabled: !!peopleID }
  );

  // Set formik fields on update people
  useEffect(() => {
    if (data && data.length > 0) {
      formik.setFieldValue("name", data[0]?.name);
      formik.setFieldValue("email", data[0]?.email);
      formik.setFieldValue("phoneNo", data[0]?.phoneNo);
    }
  }, [data]);

  // Create Company   ...
  const { mutate: peopleApi } = useMutation(
    (body) => {
      if (peopleID && peopleID !== null) {
        return PeopleApi.updatePeople(peopleID, body);
      } else {
        return PeopleApi.createPeople(body);
      }
    },
    {
      onSuccess: (success) => {
        refetch();
        toast.success(success.message);
      },
      onError: (error) => {
        toast.error(error);
      },
    }
  );

  const addPeople = (body) => {
    peopleApi(body);
    handleClose();
    formik.resetForm();
  };

  return (
    <Dialog
      open={open}
      sx={{
        "& .MuiDialog-container": {
          "& .MuiPaper-root": {
            width: "100%",
            maxWidth: "470px",
          },
        },
      }}
    >
      <DialogTitle className={classes.dialogTitle}>
        {peopleID ? "Update Notification user" : "Create New Notification user"}
        <IconButton onClick={handleClose}>
          <Close style={{ color: "white" }} />
        </IconButton>
      </DialogTitle>
      <form autoComplete="off" onSubmit={formik.handleSubmit}>
        {/* Name text field */}
        <DialogContent>
          <TextField
            margin="dense"
            name="name"
            label="Name"
            fullWidth
            size="small"
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
          {/* Email Text Field */}
          <TextField
            margin="dense"
            name="email"
            label="Email"
            fullWidth
            size="small"
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
            helperText={formik.touched.email && formik.errors.email}
          />
          {/* Email Text Field */}
          <TextField
            margin="dense"
            name="phoneNo"
            label="Phone"
            fullWidth
            size="small"
            InputProps={{
              inputComponent: IMaskInput,
              inputProps: {
                mask: "+1 (000) 000-0000",
              },
            }}
            value={formik.values.phoneNo}
            onChange={formik.handleChange}
            error={formik.touched.phoneNo && Boolean(formik.errors.phoneNo)}
            helperText={formik.touched.phoneNo && formik.errors.phoneNo}
          />
          {/* Buttons Section */}
          <DialogActions>
            <Button
              className={classes.deleteButton}
              style={{ backgroundColor: "#ed342a", color: "white" }}
              onClick={handleClose}
              variant="contained"
            >
              <Close fontSize="small" className={classes.iconsStyle} /> Cancel
            </Button>
            <Button variant="contained" type="submit">
              <Save fontSize="small" className={classes.iconsStyle} /> Save
            </Button>
          </DialogActions>
        </DialogContent>
      </form>
    </Dialog>
  );
};

export default FormDialog;
