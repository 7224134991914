import React from "react";
import { Typography, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth"; // Ensure this is updated to use MSAL
import dynamicStyle from "./styles";
import { toast } from "react-toastify";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import { Microsoft } from "@mui/icons-material/";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";

const theme = createTheme({ marginLeft: 0 });

const SignIn = () => {
  const siteName = "CCG Contracts";

  const classes = dynamicStyle();
  const navigate = useNavigate();
  const { signin, isLoggedIn } = useAuth(); // Using updated useAuth hook with MSAL

  // Function to handle Microsoft login
  const handleMicrosoftSignIn = async () => {
    try {
      await signin(() => navigate("/")); // On successful sign in, navigate to the home page
    } catch (error) {
      toast.error("Failed to sign in with Microsoft.");
    }
  };

  // Check if the user is already logged in
  if (isLoggedIn) {
    navigate("/"); // Redirect to home if already signed in
  }

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          height: "90vh", // Make the Box fill the entire viewport height
          display: "flex", // Enable flexbox
          flexDirection: "column", // Stack children vertically
          justifyContent: "center", // Center vertically in the container
          alignItems: "center", // Center horizontally in the container
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography style={{ textAlign: "center" }} component="h1" variant="h5">
          Cam Clark Automotive Group
        </Typography>
        <Typography style={{ textAlign: "center" }} component="h1" variant="h5">
          {siteName}
        </Typography>
        <Button
          startIcon={<Microsoft />}
          onClick={handleMicrosoftSignIn}
          variant="contained"
          sx={{ mt: 3, mb: 2 }}
        >
          Sign In with Microsoft
        </Button>
      </Box>
    </ThemeProvider>
  );
};

export default SignIn;
