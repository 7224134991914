import { makeStyles } from "@mui/styles";

const dynamicStyle = makeStyles((theme) => ({
  main: {
    flex: 1,
  },
  buttonsRow: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: "20px",
    marginTop: "15px",
  },
  selctOption: {
    marginBottom: "20px",
    marginTop: "15px",
    flexDirection: "row",
  },
  fitersView: {
    textAlign: "right",
  },
}));

export default dynamicStyle;
