import React from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Backdrop from "@mui/material/Backdrop";
import { Grid } from "@mui/material";

import dynamicStyle from "./styles";

const Spinner = (props) => {
  const { open, loading, size } = props;

  const classes = dynamicStyle();

  return (
    <div>
      <Backdrop open={open}>
        <Grid item xs={12} sm={10} md={8} lg={6}>
          {loading && (
            <Grid container className={classes.spinnerContainer}>
              <CircularProgress size={size} />
            </Grid>
          )}
        </Grid>
      </Backdrop>
    </div>
  );
};

export default Spinner;
