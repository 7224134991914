import { makeStyles } from "@mui/styles";

const dynamicStyle = makeStyles((theme) => ({
  spinnerContainer: {
    justifyContent: "center",
    alignItems: "center",
    position: "fixed",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

export default dynamicStyle;
