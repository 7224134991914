import { makeStyles } from "@mui/styles";

const dynamicStyle = makeStyles((theme) => ({
  main: {
    display: "flex",
    "& .MuiGrid-item": {
      marginTop: "30px",
      marginBottom: "20px",
    },
  },
}));

export default dynamicStyle;
