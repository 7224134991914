import * as React from "react";
import { Link, useLocation } from "react-router-dom";
import { styled } from "@mui/system";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import HomeIcon from "@mui/icons-material/Home";
import SearchIcon from "@mui/icons-material/Search";
import ViewListIcon from "@mui/icons-material/ViewList";
import ListIcon from "@mui/icons-material/List";
import {
  Drafts,
  NotAccessibleOutlined,
  NotificationAdd,
  Settings,
} from "@mui/icons-material";

import { ListItemIcon } from "@mui/material";

import { useAuth } from "../../hooks/useAuth";

// Create styled components for the sidebar and top bar
const drawerWidth = 240;

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const SideBarDrawer = ({ open, setOpen, isSmallScreen }) => {
  const { isLoggedIn, user } = useAuth();
  const location = useLocation();
  console.log("user", user);
  // Return null if user is not logged in
  if (!isLoggedIn) {
    return null;
  }

  const isActive = (path) => {
    return location.pathname === path;
  };

  const handleListItemClick = () => {
    if (isSmallScreen) {
      setOpen(false);
    }
  };

  return (
    <>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            marginTop: "64px",
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <div onClick={handleListItemClick}>
          <List>
            {/* Home */}
            <ListItem button component={Link} to="/" selected={isActive("/")}>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
            {/* Drafts */}
            {user?.userType == "A" ? (
              <ListItem
                button
                component={Link}
                to="/drafts"
                selected={isActive("/drafts")}
              >
                <ListItemIcon style={{ display: "flex-end" }}>
                  <Drafts />
                </ListItemIcon>
                <ListItemText primary="Drafts" />
              </ListItem>
            ) : (
              <></>
            )}
            {/* Global Search */}
            <ListItem
              button
              component={Link}
              to="/search"
              selected={isActive("/search")}
            >
              <ListItemIcon>
                <SearchIcon />
              </ListItemIcon>
              <ListItemText primary="Global Search" />
            </ListItem>
            {user?.userType == "A" ? (
              <>
                <ListItem
                  button
                  component={Link}
                  to="/setting"
                  selected={isActive("/setting")}
                >
                  <ListItemIcon>
                    <NotificationAdd />
                  </ListItemIcon>
                  <ListItemText primary="Notificaiton Users" />
                </ListItem>
                {/* Logs */}
                <ListItem
                  button
                  component={Link}
                  to="/logs"
                  selected={isActive("/logs")}
                >
                  <ListItemIcon>
                    <ListIcon />
                  </ListItemIcon>
                  <ListItemText primary="Logs" />
                </ListItem>
              </>
            ) : (
              <></>
            )}
          </List>
        </div>
      </Drawer>
    </>
  );
};

export default SideBarDrawer;
