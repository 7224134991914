import { api } from "../../utils";
import { GET_STORES } from "../ApiConstants";

class StoreApi {
  static sharedInstance = new StoreApi();
  constructor() {
    if (StoreApi.sharedInstance != null) {
      return StoreApi.sharedInstance;
    }
  }
  //   Get Stores ..
  async getStores() {
    try {
      const response = await api.get(GET_STORES);
      const { success, data, message } = response.data;
      if (success) {
        return data;
      } else {
        return message;
      }
    } catch (error) {
      return error;
    }
  }
}

export default StoreApi.sharedInstance;
