import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DownloadIcon from "@mui/icons-material/Download";
import dynamicStyle from "./styles";
import React from "react";
import ContractApi from "../../../../services/Api/Contract.Api";
import FileDownload from "js-file-download";

const AttachmentList = ({ open, setOpen, data, company }) => {
  const classes = dynamicStyle();
  const handleClose = () => {
    setOpen(false);
  };

  const handleDownload = async (companyName, email, filename) => {
    const response = await ContractApi.downloadAttachment(
      companyName,
      email,
      filename
    );
    FileDownload(response.data, filename);
  };
  return (
    <Grid>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "430px",
              maxHeight: "400px",
            },
          },
        }}
      >
        <DialogTitle className={classes.dialogTitle}>
          Attachment List
          <IconButton onClick={handleClose}>
            <CloseIcon style={{ color: "white" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          {/* attachment list */}
          {data ? (
            <>
              <List>
                {data &&
                  data?.map((item, index) => (
                    <ListItem key={index}>
                      <ListItemText primary={item?.name.split(".")[0]} />
                      {/* <ListItemText primary={item?.name.replace(".pdf", "")} /> */}

                      <ListItemSecondaryAction>
                        <IconButton
                          onClick={() =>
                            handleDownload(
                              item?.path.split("\\")[1], // Getting the company name from the current  file path
                              item?.email,
                              item?.name
                            )
                          }
                        >
                          <DownloadIcon />
                        </IconButton>
                      </ListItemSecondaryAction>
                    </ListItem>
                  ))}
              </List>
            </>
          ) : (
            <Typography>No attachment exists</Typography>
          )}
        </DialogContent>
      </Dialog>
    </Grid>
  );
};

export default AttachmentList;
