import { api } from "../../utils";
import { SING_UP, SIGN_IN } from "../ApiConstants";
import LocalStorage from "../../managers/LocalStorage";

class AuthApi {
  static sharedInstance = new AuthApi();
  constructor() {
    if (AuthApi.sharedInstance != null) {
      return AuthApi.sharedInstance;
    }
  }
  //  Register user ..
  async registerUser(body) {
    try {
      const response = await api.post(SING_UP, body);
      const { success, data, message } = response.data;
      if (success) {
        return data;
      } else {
        return message;
      }
    } catch (error) {
      return error;
    }
  }
  //   Login user ..
  async loginUser(body) {
    try {
      const response = await api.post(SIGN_IN, body);
      const { success, data, message } = response.data;
      if (success) {
        LocalStorage.setToken(response.data.token);
        LocalStorage.setUser(response.data.user);
        return response.data;
      } else {
        return response.data;
      }
    } catch (error) {
      return error;
    }
  }
}

export default AuthApi.sharedInstance;
