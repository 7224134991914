import axios from "axios";
import LocalStorage from "../../managers/LocalStorage";

const api = axios.create({
  baseURL: "https://contract.camclarkgroup.com/api/v2/",
});

api.interceptors.request.use(function (config) {
  let jwt = sessionStorage.getItem("jwt");
  try {
    if (jwt) config.headers.Authorization = `Bearer ${jwt}`;

    return config;
  } catch (error) {
    console.log("Error in setting auth header globally !", error);
  }
});

api.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    console.log(error);
    if ([400, 401, 403].includes(error.response?.status)) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }

    return Promise.reject(error);
  }
);

export default api;
