import {
  AppBar,
  Button,
  Grid,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthContext, useAuth } from "../../hooks/useAuth";
import LocalStorage from "../../managers/LocalStorage";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { useIdleTimer } from "react-idle-timer";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { CountdownCircleTimer } from "react-countdown-circle-timer";

import MenuIcon from "@mui/icons-material/Menu";

import dynamicStyle from "./styles";

const timeout = 15 * 60_000;
const promptBeforeIdle = 20_000;

const RenderTime = ({ remainingTime }) => {
  return (
    <Grid sx={{ alignItems: "center" }}>
      <Typography>{remainingTime}</Typography>
    </Grid>
  );
};

const Navbar = ({ open, handleDrawerOpen, setOpen }) => {
  const classes = dynamicStyle();
  const navigate = useNavigate();
  const { isLoggedIn, user, signout, lock } = useAuth();
  const location = useLocation();

  const [state, setState] = useState("Active");
  const [remaining, setRemaining] = useState(timeout);
  const [openIdle, setOpenIdle] = useState(false);

  const onIdle = () => {
    setState("Idle");
    setOpenIdle(false);
    if (isLoggedIn) {
      lockOut();
    }
  };

  const onActive = () => {
    setState("Active");
    setOpenIdle(false);
  };

  const onPrompt = () => {
    setState("Prompted");
    setOpenIdle(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  useEffect(() => {
    let interval;
    if (isLoggedIn === true) {
      interval = setInterval(() => {
        setRemaining(Math.ceil(getRemainingTime() / 1000));
      }, 500);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isLoggedIn]);

  const handleStillHere = () => {
    activate();
  };

  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0);
  const seconds = timeTillPrompt > 1 ? "seconds" : "second";

  const handleLogout = () => {
    signout();
    setOpen(false);
    toast.success("Logout successfully!", { autoClose: 2000 });
    navigate("/signin");
    activate();
  };
  const lockOut = () => {
    lock();
    setOpen(false);
    toast.success("Logout successfully!", { autoClose: 2000 });
    navigate("/signin");
    activate();
  };

  return (
    <div className={classes.root}>
      <Toolbar sx={{ zIndex: 1 }}>
        {isLoggedIn && (
          <>
            {open ? (
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={handleDrawerOpen}
                edge="start"
              >
                <ChevronLeftIcon />
              </IconButton>
            ) : (
              <>
                {location.pathname.startsWith("/contract/") ? null : (
                  <>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleDrawerOpen}
                      edge="start"
                    >
                      <MenuIcon />
                    </IconButton>
                  </>
                )}
              </>
            )}
          </>
        )}

        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
          {location.pathname.startsWith("/contract/") ? (
            <>
              <Link to="/" style={{ textDecoration: "none", color: "white" }}>
                Contract Detail
              </Link>
            </>
          ) : (
            <Link to="/" style={{ textDecoration: "none", color: "white" }}>
              CCG Contracts
            </Link>
          )}
        </Typography>
        {!isLoggedIn ? (
          <></>
        ) : (
          <>
            <Grid className={classes.Username}>
              <Typography>{`${user?.name}`}</Typography>
            </Grid>

            <Button variant="contained" onClick={lockOut}>
              Lock
            </Button>
            <Button variant="contained" onClick={handleLogout}>
              Logout
            </Button>
          </>
        )}
      </Toolbar>

      {isLoggedIn && (
        <Dialog
          open={openIdle}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "470px",
              },
            },
          }}
          hideBackdrop={true}
        >
          <DialogTitle>Do you want to continue or logout?</DialogTitle>
          <DialogContent>
            <Typography
              sx={{ color: "#9E9E9E", fontSize: 15, fontWeight: 700 }}
            >
              If no more further activity found in one minute you will be
              loggedout
            </Typography>
            <DialogActions>
              <Button
                onClick={handleStillHere}
                variant="contained"
                color="primary"
              >
                Keep Me Loggedin
              </Button>
              <Button
                onClick={handleLogout}
                variant="contained"
                color="inherit"
              >
                Logout
              </Button>

              <div style={{ marginLeft: "5px" }}>
                <CountdownCircleTimer
                  isPlaying
                  strokeWidth={4}
                  trailStrokeWidth={4}
                  duration={60}
                  colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
                  colorsTime={[60, 50, 40, 0]}
                  size={35}
                >
                  {RenderTime}
                </CountdownCircleTimer>
              </div>
            </DialogActions>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
};

export default Navbar;
