import { makeStyles } from "@mui/styles";

const dynamicStyle = makeStyles((them) => ({
  root: {
    "& .MuiButtonBase-root": {
      backgroundColor: "transparent",
      color: "inherit",
    },
  },
  links: {
    textDecoration: "none",
    margin: "5px",
    color: "white",
    _hover: {
      boxShadow: "none",
    },
  },
  Username: {
    marginRight: "15px",
    textAlign: "center",
    "& .MuiTypography-root ": {
      fontWeight: "bold",
      fontSize: "17px",
    },
  },
  modal: {
    width: "40em",
    background: "#3498db",
    borderRadius: "1em",
    padding: "1em",
    position: "absolute",
    flexDirection: "column",
    alignItems: "center",
    left: "calc(50vw - 10em)",
    top: "calc(50vh - 10em)",
    display: "flex",
    justifyContent: "center",
  },
}));

export default dynamicStyle;
