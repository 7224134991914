import React, { useState } from "react";
import Drawer from "@mui/material/Drawer";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Card,
  CardHeader,
  Grid,
  TextField,
  Typography,
} from "@mui/material";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

import dynamicStyle from "./styles";

const Filter = (props) => {
  const { open, setOpen, filters, setFilters, onFilter } = props;
  const classes = dynamicStyle();

  const [startDateStart, setStartDateStart] = useState(null);
  const [startDateEnd, setStartDateEnd] = useState(null);
  const [expiryDateStart, setExpiryDateStart] = useState(null);
  const [expiryDateEnd, setExpiryDateEnd] = useState(null);

  const onStartDateStart = (value) => {
    setStartDateStart(value);
  };

  const onStartDateEnd = (value) => {
    setStartDateEnd(value);
  };

  const onExpiryDateStart = (value) => {
    setExpiryDateStart(value);
  };
  const onExpiryDateEnd = (value) => {
    setExpiryDateEnd(value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    onFilter &&
      onFilter({
        startDateStart,
        startDateEnd,
        expiryDateStart,
        expiryDateEnd,
      });

    setOpen(false);
    // onFilter && onFilter();
  };
  const handleClearFilters = (event) => {
    event.preventDefault();
    setStartDateStart(null);
    setStartDateEnd(null);
    setExpiryDateStart(null);
    setExpiryDateEnd(null);
    onFilter &&
      onFilter({
        startDateStart: null,
        startDateEnd: null,
        expiryDateStart: null,
        expiryDateEnd: null,
      });
    setOpen(false);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  return (
    <Drawer
      classes={{ paper: classes.drawer }}
      anchor="right"
      open={open}
      onClose={handleDrawerClose}
    >
      <Card>
        <CardHeader
          avatar={
            <CloseIcon
              onClick={handleDrawerClose}
              style={{ cursor: "pointer" }}
            />
          }
          title="Close"
        />
      </Card>
      <div role="presentation">
        <Typography className={classes.filterTitle} variant="h6">
          Started Date
        </Typography>
        {/* Form  */}
        <Grid container className={classes.textFieldGrid} spacing={2}>
          {/* Strated Date Start */}
          <Grid item xs={6} sm={12} lg={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                variant="outlined"
                name="start"
                sx={{ width: "100%" }}
                label="Start"
                value={startDateStart}
                onChange={(val) => onStartDateStart(val)}
                slotProps={{
                  textField: {
                    size: "small",
                    variant: "outlined",

                    // value={startDateStart}
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          {/* Started Date End */}
          <Grid item xs={6} sm={12} lg={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                format="YYYY-MM-DD"
                variant="outlined"
                sx={{ width: "100%" }}
                name="end"
                label="End"
                value={startDateEnd}
                onChange={(val) => onStartDateEnd(val)}
                slotProps={{
                  textField: {
                    size: "small",
                    variant: "outlined",
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
        </Grid>
        <Typography className={classes.filterTitle} variant="h6">
          Expiry Date
        </Typography>
        <Grid container className={classes.textFieldGrid} spacing={2}>
          {/* Expirt Date Start */}
          <Grid item xs={6} sm={12} lg={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                variant="outlined"
                sx={{ width: "100%" }}
                name="start"
                label="Start"
                value={expiryDateStart}
                onChange={(val) => onExpiryDateStart(val)}
                slotProps={{
                  textField: {
                    size: "small",
                    variant: "outlined",
                  },
                }}
              />
            </LocalizationProvider>
          </Grid>
          <Grid item xs={6} sm={12} lg={12}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                variant="outlined"
                sx={{ width: "100%" }}
                name="end"
                label="End"
                value={expiryDateEnd}
                onChange={(val) => onExpiryDateEnd(val)}
                slotProps={{
                  textField: {
                    size: "small",
                    variant: "outlined",
                  },
                }}
              />
            </LocalizationProvider>
            {/* Actions Section */}

            <Grid container>
              <Grid
                item
                lg={12}
                xs={12}
                sm={12}
                className={classes.buttonsGrid}
              >
                <Button
                  style={{ width: "100%" }}
                  variant="outlined"
                  onClick={handleClearFilters}
                >
                  Clear
                </Button>
              </Grid>
              <Grid
                item
                lg={12}
                xs={12}
                sm={12}
                className={classes.buttonsGrid}
              >
                <Button
                  style={{ width: "100%" }}
                  color="primary"
                  type="submit"
                  variant="contained"
                  onClick={handleSubmit}
                >
                  Apply Filters
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Drawer>
  );
};

export default Filter;
